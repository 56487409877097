












import { PostStore } from '@/stores/post-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({}) postStore!: PostStore
  @Prop({ default: false }) enableBlind!: boolean

  redirectTag(tagContent: string) {
    const searchTerm = tagContent.replaceAll('#', '')
    if (this.$router.currentRoute.path === '/search/forum') {
      this.$router.replace(`/search/forum?text=${searchTerm}`)
    } else {
      this.$router.push(`/search/forum?text=${searchTerm}`)
    }
    this.$forceUpdate()
  }
}
